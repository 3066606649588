import { AircraftSortingInput } from '../../../../shared/graphql-types';

export class AircraftLookup {
    searchTerm: string = null;
    skip = 0;
    limit = 20;
    total = 0;
    deleted: boolean = false;
    d085OpsSpecsApproval: boolean = true;
    sorting: AircraftSortingInput = { tailNumber: true };
    insApproveOnly: boolean = null;
    completed: boolean = null;
    approvedBy: string = null;
    aircraftCategory: number = null;
    homeBase: string = null;
    homeBaseCountry: string = null;
    homeBaseServiceAreas: Array<string> = null;
    onFleet: boolean = null;
    marketplacePhotosApproval: boolean = null;
    aircraftAge: number = null;
    refurbishmentAge: number = null;

    constructor(data?: any) {
      if (data) {
        this.searchTerm = data.searchTerm ?? null;
        this.deleted = data.deleted ?? null;
        this.d085OpsSpecsApproval = data.d085OpsSpecsApproval ?? null;
        this.insApproveOnly = data.insApproveOnly ?? null;
        this.completed = data.completed ?? null;
        this.approvedBy = data.approvedBy ?? null;
        this.aircraftCategory = data.aircraftCategory ?? null;
        this.homeBase = data.homeBase ?? null;
        this.homeBaseCountry = data.homeBaseCountry ?? null;
        this.homeBaseServiceAreas = data.homeBaseServiceAreas ?? null;
        this.onFleet = data.onFleet ?? null;
        this.marketplacePhotosApproval = data.marketplacePhotosApproval ?? null;
        this.aircraftAge = data.aircraftAge ?? null;
        this.refurbishmentAge = data.refurbishmentAge ?? null;
      }
    }
  }